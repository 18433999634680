#content-flex {
  display: flex;
  gap: 2rem;
  height: calc(100vh - 4rem);
  max-width: 1100px;
  margin: 0 auto;
}

#card-holder {
  flex-grow: 0;
  flex-shrink: 0;

  overflow-y: auto;
  padding-right: 1rem;
}

main {
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
  padding-right: 1rem;
}

#color-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
