#card {
  width: 550px;
  min-height: 300px;
  background: var(--card-color-1);
  color: var(--card-text-color);
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
}

#card * {
  color: var(--card-text-color);
}

#card #alignment svg {
  width: 28px;
  height: 28px;
}

#card #alignment svg * {
  stroke-width: 30px;
}

#card > div {
  padding: 1rem;
}

#card p {
  font-weight: 500;
  margin-top: 0.5rem;
  font-family: "Uni Neue Italic";
}

#card .italic {
  font-style: italic;
}

#card .center {
  text-align: center;
}

#card #title {
  font-size: 90px;
  font-weight: 800;
  text-align: center;
  line-height: 4.5rem;
  margin-top: 3rem;

  background: linear-gradient(
    90deg,
    var(--card-title-color-1),
    var(--card-title-color-2)
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;

  overflow: visible;
}

#title-holder {
  overflow: visible;
}

#card #gradient {
  width: 100%;
  padding-top: 0;

  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    var(--card-color-1) 0%,
    var(--card-color-2) 100%
  );
}

#card .subtitle {
  font-size: 28px;
  font-weight: 800;
  text-transform: uppercase;
}

#card .content {
  font-size: 16px;
  line-height: 21px;
}

#card .bold {
  font-weight: 700;
  font-family: "Uni Neue Italic";
}

#card #alignment {
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-family: "Meedori";
  font-weight: 500;
  font-size: 20px;
}

#card #alignment img {
  width: 28px;
  height: 28px;
}

#card #corner1 {
  position: absolute;
  border-top: var(--card-text-color) 2px solid;
  border-left: var(--card-text-color) 2px solid;
  width: 40px;
  height: 40px;
  border-top-left-radius: 1rem;
  top: 0.2rem;
  left: 0.2rem;
}

#card #corner2 {
  position: absolute;
  border-right: var(--card-text-color) 2px solid;
  border-bottom: var(--card-text-color) 2px solid;
  width: 40px;
  height: 40px;
  border-bottom-right-radius: 1rem;
  bottom: 0.2rem;
  right: 0.2rem;
}
