@font-face {
  font-family: "Uni Neue Italic";
  font-weight: 900;
  src: url(../assets/fonts/UniNeue/UniNeue-Trial-HeavyItalic.ttf);
}

@font-face {
  font-family: "Uni Neue Italic";
  font-weight: 500;
  src: url(../assets/fonts/UniNeue/UniNeue-Trial-RegularItalic.ttf);
}

@font-face {
  font-family: "Uni Neue Italic";
  font-weight: 700;
  src: url(../assets/fonts/UniNeue/UniNeue-Trial-BoldItalic.ttf);
}

@font-face {
  font-family: "Meedori";
  font-weight: 500;
  src: url(../assets/fonts/Meedori/Meedori-Sans-Bold.ttf);
}

@font-face {
  font-family: "Relapse";
  src: url(../assets/fonts/Relapse/relapse.ttf);
}
