select {
  background: var(--color-bg2);
  width: 100%;
  padding: 0.75rem 0.9rem;
  border-radius: 0.5rem;
  color: white;

  border: 2px solid var(--color-darken);
  resize: none;
}
