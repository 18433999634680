:root {
  --font-sans: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  --font-mono: "JetBrains Mono", Menlo, Monaco, "Lucida Console",
    "Liberation Mono", "DejaVu Sans Mono", "Bitstream Vera Sans Mono";
  --font-title: Relapse, Inter, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;

  --color-primary: #37afff;
  --color-bg1: #141518;
  --color-bg2: #282a2e;
  --color-darken: #43464d;
  --color-text1: #ffffff;
  --color-text2: #cecece;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;

  font-family: var(--font-sans);
  color: var(--color-text1);

  transition-property: box-shadow, background, border-color, color, fill, stroke;
  transition-duration: 175ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

*::-webkit-scrollbar {
  width: "18px";
}

*::-webkit-scrollbar-thumb {
  border: "6px solid rgba(0, 0, 0, 0)";
  background-clip: "padding-box";
  border-radius: "9999px";
  background-color: "#AAAAAA";
}

body {
  width: 100%;
  height: 100vh;
  padding: 2rem;
  overflow: hidden;

  background: var(--color-bg1);
  color: var(--color-text1);
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.sketch-picker {
  width: 250px;
}
