textarea,
input {
  background: var(--color-bg2);
  width: 100%;
  padding: 0.75rem 0.9rem;
  border-radius: 0.5rem;
  color: white;

  border: 2px solid var(--color-darken);
  resize: none;

  min-width: 0;
  flex: 1;
  flex-shrink: 1;
  flex-grow: 1;
}

textarea:active,
textarea:focus,
input:active,
input:focus {
  border: 2px solid var(--color-primary);
}

.input-flex {
  display: flex;
  align-items: center;
  gap: 1rem;
}
