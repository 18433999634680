.button {
  background: var(--button-color-1);
  color: var(--button-text-color);
  padding: 0.8rem 1rem;
  font-size: 16px;
  font-weight: 500;
  border-radius: 0.5rem;
  cursor: pointer;
}

.button:hover {
  background: var(--button-color-2);
}

.button * {
  color: var(--button-text-color);
}
